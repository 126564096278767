import { Component, OnInit, Inject } from '@angular/core';
import { Reminder } from 'src/app/core/model/reminder';
import * as dateFns from 'date-fns';
import { INotificationService, Severity } from '../../interfaces';
import { NotificationService } from '../../services';
import { Router, RouterModule } from '@angular/router';

export class ReminderNotification {
    reminder: Reminder;
    num: number;
    visible: boolean;
}

@Component({
    selector: 'app-info-box',
    templateUrl: 'infoBox.component.html',
    styleUrls: ['infoBox.component.scss'],
})
export class InfoBoxComponentComponent implements OnInit {
    dateFns = dateFns;
    notification: any = [];
    reminders: Reminder[] = [];
    hideReminders: number[] = [];
    num = 0;
    time = 30000;

    constructor(
        private router: Router,
        @Inject(NotificationService) private notificationSvc: INotificationService
    ) {
    }

    ngOnInit(): void {
        this.notificationSvc.subscribe((p, t, m) => this.present(p, t, m));
        this.notificationSvc.subscribeReminders((r) => this.showReminders(r));
    }

    navigate = (url: string) => {
        this.router?.navigate([url])
    }

    present(severity: Severity, title: string, message: string): void {
        this.showMessage(severity, title, message);
    }

    showReminders = (reminders: Reminder[]): void => {
        this.reminders = [];
        reminders.forEach(reminder => {
            const added = this.hideReminders.find(id => id === reminder.id);
            if (!added) { this.reminders.push(reminder) }
        })
    }

    closeReminder(reminder: Reminder) {
        this.hideReminders.push(reminder.id);
        const index = this.reminders.findIndex(x => x.id === reminder.id);
        this.reminders.splice(index, 1);
    }

    dismissReminder = (reminder: Reminder) => {
        this.notificationSvc.dismiss(reminder);
    }

    showMessage(severity: Severity, title: string, message: string) {
        this.num += 1;
        const notification = {
            severity,
            title,
            message,
            visible: true,
            num: this.num,
        };
        this.notification.push(notification);
        this.time = severity === Severity.warning ? 5000 : 30000;
        setTimeout(() => { this.close(notification.num) }, this.time);
    }

    close(num: number) {
        const item = this.notification.findIndex(x => x.num === num);
        this.notification.splice(item, 1);
    }

    getSeverity(id: number): string {
        return Severity[id].toString();
    }
}
