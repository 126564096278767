<div class="drop-container drag card" ngFileDrop ngFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput"
     [ngClass]="{ 'is-drop-over': dragOver }">
    <span class="title-card" [ngClass]="{'marg-hide': hide }" (click)="hide = !hide">ATTACHMENTS
        <span class="count" *ngIf="_files.length > 0"> {{ _files.length}}</span>
        <span *ngIf="files.length > 0" class="selected">selected: {{ files.length}} {{files.length > 1 ? 'files' : 'file'}}</span>
        <span class="right">
            <i class="fa {{hide ? 'fa-chevron-down' : 'fa-chevron-up' }}" aria-hidden="true"></i>
        </span>
    </span>
  <span [ngClass]="{'hide': hide }">
        <div class="pre-loader" [ngClass]="{ 'loader': loading }"></div>
        <div class="all-files">
            <div class="file-element" *ngFor="let item of _files; let i=index">
                <div class="cancel-file" (click)="removeFile(item.id, item.name)">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </div>
                <a class="download-file" href="{{this.url +'/'+ this.getObjId +'/assets' +'/'+ item.id +'/'+ 'download'}}"> Download
                    <i class="fa fa-download" aria-hidden="true"></i>
                </a>
                <a target="_blank" href="{{this.url +'/'+ this.getObjId +'/assets' +'/'+ item.id +'/'+ 'view'}}">
                    <div class="icon">
                        <i [class]="getIconforType(item.name)" aria-hidden="true"></i>
                    </div>
                    <div class="name-file">
                        {{item.name}}
                    </div>
                </a>

            </div>
        </div>

    <!-----start------- new files------------ -->
        <div *ngIf="files.length > 0">
            <div class="all-files drag-files">
                <div class="file-element" *ngFor="let item of files; let i=index">
                    <div class="cancel-file" (click)="cancelUpload(item.id)">
                        <i class="fa fa-times" aria-hidden="true"></i>
                    </div>
                    <div class="icon">
                        <i [class]="getIconforType(item.name)" aria-hidden="true"></i>
                    </div>
                    <div class="name-file">
                        {{item.name}}
                    </div>
                </div>
            </div>
        </div>
    <!------end------ new files------------ -->

        <p class="background-text">
            <i class="fa fa-cloud-download" aria-hidden="true"></i>
            <br> Drag & Drop files here
        </p>

        <input id="fileInput" id="fileUpload" #fileUpload type="file" multiple ngFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput"
               hidden="hidden">
        <div class="all-btn">
            <button  class="btn btn-default" (click)="bringFileSelector()">
                <i class="fa fa-plus" aria-hidden="true"></i> add attachments
            </button>
            <button *ngIf="files.length > 0" class="btn btn-primary save" (click)="startUpload()">
                <i class="fa fa-upload" aria-hidden="true"></i> upload
            </button>
        </div>
    </span>
</div>

<app-confirm></app-confirm>
