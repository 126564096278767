<div>
    <div *ngIf="general" id="reminder-btn" class="reminder-count"
        title="{{showCalendar ? 'Hide calendar' : 'Show calendar'}}" (click)="openCalendar()">
        <div>
            <span>{{notificationReminders.length > 0 ? notificationReminders.length : ''}}</span>
            <div class="notification-left"></div>
        </div>
        <i class="fa fa-bell-o"></i>
    </div>


    <div *ngIf="showCalendar || editReminder" class="reminder-body" (click)="outsideClick()">
        <div class="reminder-main" (click)="outsideClick($event)">
            <div class="calendar" *ngIf="showCalendar">
                <div *ngIf="general">
                    <div class="header row-cal flex-middle">
                        <div class="col-cal col-start" (click)="prevMonth($event)">
                            <i class="fa fa-chevron-left icon-left"></i>
                        </div>
                        <div class="col-cal col-center">
                            <span>{{dateFns.format(currentMonth, dateFormat)}}</span>
                        </div>
                        <div class="col-cal col-end" (click)="nextMonth($event)">
                            <i class="fa fa-chevron-right icon-right"></i>
                        </div>
                    </div>
                    <div class="days row-cal">
                        <div *ngFor="let i of [0,1,2,3,4,5,6]" class="col-cal col-center">
                            {{dateFns.format(dateFns.addDays(daysStartDate, i), daysFormat)}}
                        </div>
                    </div>
                    <div class="body">
                        <div *ngFor="let row of calendar.row" class="row-cal">
                            <div (click)="addReminder(date.date, $event)" *ngFor="let date of row.data" class="col-calendar col-cal cell {{dateFns.isSameMonth(date.date, currentMonth)? '' : 'out-month'}} 
                                        {{dateFns.isToday(date.date)? 'current-day' : ''}}">
                                <span class="day number">{{dateFns.format(date.date, "DD")}}</span>
                                <span (click)="addReminder(date.date, $event)" class="add-button"
                                    title="Create a new reminder">
                                    +<i class="fa fa-bell-o" aria-hidden="true"></i>
                                </span>

                                <div class="reminder-list">
                                    <div *ngFor="let reminder of date.reminders" (click)="clickEvent(reminder, $event)">
                                        <div *ngIf="showDismiss || (!showDismiss && !reminder.isDismissed)"
                                            class="reminder-item"
                                            [ngClass]="{'reminder-dismiss' : reminder.isDismissed}">
                                            <span class="reminder-item-time">
                                                {{dateFns.format(reminder.activeAfterUtc, "hh:mm a")}}
                                            </span>
                                            <span *ngIf="reminder.relatedAppUserId && reminder.data && reminder.data.category && reminder.data.category.name" 
                                            class="reminder-item-username" (click)="navigate(reminder, $event)" title="Profile details">
                                                {{reminder.data.category.name }}
                                            </span>
                                            <span class="reminder-item-text" title="{{reminder.text}}">
                                                {{reminder.headerText ? reminder.headerText : reminder.text }}
                                            </span>
                                            <span class="reminder-item-action">
                                                <i (click)="deleteReminder(reminder, $event)"
                                                    class="delete-icon fa fa-trash-o" aria-hidden="true"
                                                    title="Delete"></i>
                                                <i (click)="dismissReminder(reminder, $event)" class="dismiss-icon fa"
                                                    [ngClass]="{'fa-check-square-o' : reminder.isDismissed, 'fa-square-o' : !reminder.isDismissed, 'disable-icon' : reminder.isDismissed || isFuture(reminder) }"
                                                    aria-hidden="true" title="Complete"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!general">
                    <div class="header row-cal flex-middle">

                        <div class="col-cal col-center">
                            <span>{{reminderConfig && reminderConfig.name ? reminderConfig.name : 'Reminders'}} </span>
                        </div>

                    </div>
                    <div class="body">
                        <div *ngFor="let reminder of data" >
                            <div class="reminder-item-f"  *ngIf="showDismiss || (!showDismiss && !reminder.isDismissed)"
                            (click)="clickEvent(reminder, $event)"
                            [ngClass]="{'reminder-dissmis-f' : reminder.isDismissed}">
                            <div class="reminder-item-f-date" >
                                {{dateFns.format(reminder.activeAfterUtc, "dddd")}}
                                <br>
                                {{dateFns.format(reminder.activeAfterUtc, "MM/DD/YYYY")}}
                                <br>
                                {{dateFns.format(reminder.activeAfterUtc, "hh:mm A")}}
                                <br>
                            </div>
                            <div class="reminder-item-f-content">
                                <div class="f-header"> {{reminder.headerText}} </div>
                                <div> {{reminder.text}} </div>
                            </div>

                            <div class="reminder-item-f-action">
                                <i (click)="deleteReminder(reminder, $event)" class="fa fa-trash-o" aria-hidden="true"
                                    title="Delete"></i>
                                <i (click)="dismissReminder(reminder, $event)" class="fa"
                                    [ngClass]="{'fa-check-square-o' : reminder.isDismissed, 'fa-square-o' : !reminder.isDismissed, 'disable-icon' : reminder.isDismissed || isFuture(reminder) }"
                                    aria-hidden="true" title="Complete"></i>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <!-- ________________________________EDIT___REMINDER_______________________________________ -->
            <div *ngIf="editReminder">
                <div class="reminder-edit">
                    <div class="main">
                        <div class="item">
                            <p class="head-title"><i class="fa fa-bell-o" aria-hidden="true"></i> &nbsp; <span
                                    *ngIf="!showDetails"> New</span> Reminder </p>
                            <div class="row">

                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="header">Header</label>
                                        <input #headerInput type="text" name="header" class="form-control"
                                            autocomplete="off" (blur)="headerInput.focus = null" placeholder="header"
                                            [(ngModel)]="editReminder.headerText">{{ headerInput.focus &&
                                        headerInput.focus() }}
                                    </div>
                                </div>

                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label for="unit">Text</label>
                                        <textarea class="form-control" [(ngModel)]="editReminder.text"></textarea>
                                    </div>
                                </div>

                                <div class="col-sm-12">
                                    <label>Related Student</label>
                                    <div class="form-control search-group form-group"
                                        [ngClass]="{'disableSelect': false, 'ng-submit' : !selectCompany}">
                                        <label for="search">
                                            <i class="fa fa-search" [ngClass]="{'no-visible': false}"
                                                aria-hidden="true"></i>
                                        </label>
                                        <input id="search" autocomplete="off" class="form-control input" name="search"
                                             value-formatter="firstName lastName" [list-formatter]="autocompleteUserFormat"
                                            auto-complete [(ngModel)]="editReminder.relatedAppUser"
                                            (blur)="studentChanged(editReminder.relatedAppUser)"
                                            [source]="observableSource(editReminder.relatedAppUser)"
                                            display-property-name=name min-chars="3" placeholder='search'
                                            match-formatted="true" />
                                    </div>
                                </div>
                               
                                <div class="col-sm-12 ">
                                    <div class="form-group">
                                        <label for="swd">Date & Time</label>
                                        <input type="datetime-local" name="ss" class="form-control" id="swd"
                                            [ngModel]="editReminder.activeAfterUtc | date:'yyyy-MM-ddTHH:mm'"
                                            (blur)="editReminder.activeAfterUtc = $event.target.value">
                                    </div>
                                </div>

                                <div class="col-sm-12">
                                    <div class="form-group checkbox-group">
                                        <label for="ptdy"></label>
                                        <div class="input-check"
                                            (click)="editReminder.sendEmail = !editReminder.sendEmail">
                                            <span class="span-txt">Send Email</span>
                                            <i style="font-size:20px" [class]="editReminder.sendEmail ? 'fa fa-check-square-o':'fa fa-square-o'"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="buttons">
                                <button class="btn btn-default" (click)="cancelEdit($event)">CANCEL</button>
                                <button class="btn btn-default" [ngClass]="{'disable' : (!editReminder.text && !editReminder.headerText) || editReminder.isDismissed}"
                                    (click)="createReminder()">{{showDetails ? 'UPDATE' : 'CREATE'}}</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div *ngIf="showCalendar" class="btns-schedule">
                <button type="button" class="btn btn-primary" (click)="addReminder(currentMonth, $event)">
                    <i class="fa fa-plus" aria-hidden="true"></i> NEW
                </button>
                <button type="button" class="btn btn-default" (click)="showDismiss = !showDismiss">
                    <i class="fa fa-eye-slash" aria-hidden="true"></i> {{showDismiss ? 'Hide' : 'Show'}} dismissed
                </button>
                <button type="button" class="btn btn-primary" (click)="selectEvent()">
                    <i class="fa fa-check" aria-hidden="true"></i> OK
                </button>
            </div>
        </div>
    </div>
    <app-confirm></app-confirm>
    <load-data *ngIf="showLoadData" [fullscreen]='true'></load-data>
</div>