<div>
  <!-- ______________________ NOTIFICATION_______________________________ -->
  <div class="item">
    <div class="items" *ngFor="let item of notification ">
      <div class="tool-{{getSeverity(item.severity)}} info-box" *ngIf="item.visible">
        <span class="title">{{item.title}}</span><br>
        <i [ngClass]="{'fa-exclamation' : item.severity === 1,
        'fa-exclamation-triangle': item.severity === 2,
        'fa-info': item.severity === 0}" class="fa icon icon-{{getSeverity(item.severity)}}" aria-hidden="true">
        </i>
        <div class="details">

          <span class="txt-{{getSeverity(item.severity)}}"> {{item.message}}</span>
        </div>
        <button class="btn close" (click)="close(item.num)">x</button>
      </div>
    </div>
  </div>
  <!-- ______________________ REMINDER_______________________________ -->
  <div class="reminder-notification">
    <div class="items" *ngFor="let reminder of reminders">
      <div class="info-box">
        <div class="title">
          <i class="fa fa-bell-o" aria-hidden="true"></i>
          <span class="time-reminder"> {{dateFns.format(reminder.activeAfterUtc, "hh:mm A")}}</span>
          <span class="date-reminder"> {{dateFns.format(reminder.activeAfterUtc, "MM/DD/YYYY")}}</span>
          REMINDER
        </div>
        <div class="details">
          <div *ngIf="reminder.relatedAppUserId && reminder.data && reminder.data.category && reminder.data.category.name" class="reminder-user-name">{{reminder.data.category.name}}</div>
          <div *ngIf="reminder.headerText" class="reminder-header"> {{reminder.headerText}} </div>
          <div class="reminder-text">{{reminder.text}}</div>
        </div>
        <div class="btn-action">
          <button class="btn btn-dismiss" (click)="dismissReminder(reminder)">DISMISS</button>
          <button *ngIf="reminder.data && reminder.data.category && reminder.data.category.url" class="btn btn-go" (click)="navigate(reminder.data.category.url)">GO</button>
        </div>
        <button class="btn close" title="Hide" (click)="closeReminder(reminder)">x</button>
      </div>
    </div>
  </div>

</div>