<div class="login">

  <div class="logo">
    <!--<img src="../../../assets/icon/hiller.png" alt="logo">-->
  </div>

  <div class="col-sm-12 col-md-4 offset-md-4 ">
    <div class="main">
      <app-ba-card >
        <h3 class="title-login">Login</h3>
        <div *ngIf="error" class="alert alert-danger">{{error}}</div>
        <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" >
          <!-- novalidate -->
          <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
            <label for="username">Login</label>
            <input id="username" type="text" class="form-control" name="username" [(ngModel)]="model.login" placeholder="Login" #username="ngModel" required />
          </div>
          <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
            <label for="password">Password
              <span class="title-error-pass"  [ngClass]="{ 'title-error-pass-show': f.submitted && password.value.length < 7 }">(min length 8 chars)</span>
            </label>
            <input id="password" type="password" class="form-control" name="password" [(ngModel)]="model.password" placeholder="Password" #password="ngModel" required />
          </div>
          <button [disabled]="loading" class="btn btn-success">Login
            <i *ngIf="loading" class="fa fa-spinner fa-spin" aria-hidden="true"></i>
          </button>

        </form>
      </app-ba-card>
    </div>
  </div>

</div>
