<div class="picture-group" [ngClass]="{uploading: uploadInProgress}">
  <div class="picture-wrapper" (click)="bringFileSelector();">
    <img [src]="picture" *ngIf="picture"  onerror="this.onerror=null; this.src='assets/img/theme/no-photo.png'">
    <img [src]="previewImg" *ngIf="previewImg && !!!picture">
    <!-- <img [src]="defaultPicture" *ngIf="!!!picture && !!defaultPicture && !!!previewImg"> -->

    <div class="loading" *ngIf="uploadInProgress">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>
  </div>
  <i class="ion-ios-close-outline" (click)="removePicture();" *ngIf="picture"></i>
  <a href class="change-picture" (click)="bringFileSelector();">Change profile Picture</a>
  <input #fileUpload type="file" id="fileUpload1" ngFileSelect  multiple hidden="hidden"
         ngFileSelect [options]="options"
         (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput"
         hidden="hidden" accept="image/*">
</div>
