import { BaseEntity } from 'src/app/common';
import { Company } from './company';
import { AppUser } from './profile';


export enum ReminderCategoryType {
    Company = "Company",
    Application = "Application",
}

export const getReminderCategory = (category: ReminderCategoryType, id: number, url: string = null, object: any = null, section: string = null, element: any = null): ReminderCategory => {
    switch (category) {
        case ReminderCategoryType.Application:
            return {
                id,
                name: object ? `${object.firstName} ${object.lastName}` : '',
                type: ReminderCategoryType.Application,
                url: url ? url : `/profile/details/${id}`,
                object,
                section,
                element,
            }
        case ReminderCategoryType.Company:
            return {
                id,
                name: object.name,
                type: ReminderCategoryType.Company,
                url: url ? url : `/settings/company/details/${id}`,
                object,
                section,
                element,
            }
        default:
            return new ReminderCategory();
    }
}

export enum CallerSite {
    Unknown,
    TTT,
    SchoolPortal,
}

export class ReminderCategory {
    id: number;
    type: ReminderCategoryType;
    name: string;
    url: string;
    object: any;
    section?: string;
    element?: any;
}

export class CustomDataReminder {
    url: string;
    company: Company;
    category: ReminderCategory;
}

export enum ReminderTaskType {
    SendEmail = 'SendReminderAsEmail'
}

export class ReminderTask extends BaseEntity {
    isCompleted: boolean;
    properties: { source: string; id: number };
    triggerAtUtc: Date;
    type: ReminderTaskType;
}

export class Reminder extends BaseEntity {
    activeAfterUtc: Date;
    headerText: string;
    text: string;
    site: CallerSite;
    isDismissed: boolean;
    relatedAppUserId: number;
    relatedAppUser?: AppUser;
    data?: CustomDataReminder;
    task: ReminderTask;
    sendEmail: boolean;
    time?: any;

    constructor() {
        super();
        this.data = new CustomDataReminder();
        this.activeAfterUtc = new Date(new Date().getUTCDate());
    }
}
