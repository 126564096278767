import { Component, Injector, ViewChild, Input, Inject, OnInit } from '@angular/core';
import { IBaseConfig, ObservableService } from 'src/app/common';
import { ComponentBaseDirective } from 'src/app/common/base-classes/componentBase';
import { ConfirmComponent } from 'src/app/common/components/confirm';
import { IReminderObj } from 'src/app/common/interfaces/IReminderService';
import * as dateFns from 'date-fns';
import { Reminder, ReminderCategoryType, ReminderTaskType, getReminderCategory, ReminderCategory } from 'src/app/core/model/reminder';
import { Company } from 'src/app/core/model/company';
import { AppUser } from 'src/app/core';
import ReminderService from 'src/app/common/services/reminder.service';
import { Router } from '@angular/router';


class CalendarDay { date: Date; reminders: Reminder[]; }
class Row { data: CalendarDay[] = []; }
class Calendar { row: Row[] = []; }

@Component({
    selector: 'app-reminder',
    templateUrl: 'reminder.component.html',
    styleUrls: ['reminder.component.scss'],
    providers: [ObservableService],
})

export default class ReminderComponent extends ComponentBaseDirective implements OnInit {
    @ViewChild(ConfirmComponent) confirm: ConfirmComponent;
    @Input() category: string;
    @Input() general: boolean;
    @Input() reminderConfig: ReminderCategory;
    @Input() showButton = false;
    selectCompany: Company;
    headerInput: any;
    
    reminderObj: IReminderObj;
    notificationReminders: Reminder[] = [];
    activeReminders: Reminder[] = [];
    data: Reminder[] = [];
    editReminder: Reminder;
    showCalendar = false;
    showDetails = false;
    showDismiss = true;
    timer: any;
    
    calendar: Calendar = new Calendar();
    currentMonth: Date = new Date();
    daysStartDate: Date = new Date();
    
    monthStart: Date;
    monthEnd: Date;
    startDate: Date;
    endDate: Date;
    
    dateFns = dateFns;
    dateFormat = 'MMMM YYYY';
    daysFormat = 'dddd';
    cellFormat = 'DD';
    
    constructor(
        private router: Router,
        protected injector: Injector,
        protected reminderSvc: ReminderService,
        @Inject(ObservableService) public observableSvc: ObservableService,
        ) {
            super({ componentTitle: 'Reminder', injector } as IBaseConfig);
        }
        
    ngOnInit(): void {
        this.getReminders();
    }

    observableSource(keyword: any): any {
            return this.observableSvc.observableSourceVeteranProfile.bind(`${keyword}`);
    }

    navigate = (reminder: Reminder, e: Event): void => {
        e && e.stopPropagation();
        const url = reminder.data?.category?.url;
        if (url) {
            this.showCalendar = false;
            this.router?.navigate([url])
        }
    }

    companyChanged = (company: Company): void => {
        this.editReminder.data.company = company;
        this.selectCompany = company;
    }

    studentChanged = (student: AppUser): void => {
        this.editReminder.relatedAppUserId = student.id;
        if (student) {
            this.editReminder.data.category = getReminderCategory(ReminderCategoryType.Application, student.id, null, student);
        }
    }

    getReminders = (unsubscribe: boolean = false): void => {
        if (unsubscribe) {
            if (this.reminderObj) {
                this.reminderSvc.unsubscribe(this.reminderObj.key);
            }
        } else {
            this.reminderObj = this.reminderSvc.subscribe((data: any) => this.dataLoaded(data));
            if (this.general) {
                this.notificationSvc.subscribeReminder(reminder => this.dismissReminder(reminder, null));
            }
        }
    }

    categoryReminder = (config: ReminderCategory): void => {
        this.reminderConfig = config;
        this.openCalendar();
    }

    dataLoaded = (data: Reminder[]): void => {
        if (this.general) {
            this.notificationReminders = [];
            this.activeReminders = data;
            this.setNotifications();
        }
    }

    setNotifications = (): void => {
        if (this.activeReminders.length > 0) {
            this.activeReminders.forEach((reminder: Reminder) => {
                const addedToNotification = this.notificationReminders.find(x => x.id === reminder.id);
                const addedToData = this.data.find(x => x.id === reminder.id);
                if (!addedToNotification && !reminder.isDismissed) { this.notificationReminders.push(reminder); }
                if (!addedToData) { this.data.push(reminder); }
            });
        }
        this.notificationSvc.notifyReminder(this.notificationReminders.sort((a, b) => new Date(a.activeAfterUtc).getTime() - new Date(b.activeAfterUtc).getTime()));
    }

    updateData = (): void => {
        this.getMonthDates();
        this.notificationReminders = [];
        this.setNotifications();
    }

    openCalendar = (): void => {
        this.showCalendar = !this.showCalendar;
        this.showCalendar && this.getCurrentReminders();
    }

    getCurrentReminders = (): void => {
        this.showLoadData = true;
        if (this.reminderConfig) {
            this.reminderSvc.getAllReminderBy(this.reminderConfig)
                .then((res: Reminder[]) => {
                    this.data = res.sort((a, b) => new Date(a.activeAfterUtc).getTime() - new Date(b.activeAfterUtc).getTime());
                    this.showLoadData = false;
                })
                .catch(err => this.onHttpError(err));
        } else {
            this.monthStart = dateFns.startOfMonth(this.currentMonth);
            this.monthEnd = dateFns.endOfMonth(this.monthStart);
            this.startDate = dateFns.startOfWeek(this.monthStart);
            this.endDate = dateFns.endOfWeek(this.monthEnd);

            this.reminderSvc.getAllReminder(this.formatedDate(this.startDate) as string, this.formatedDate(this.endDate) as string)
                .then((res: Reminder[]) => {
                    this.data = res;
                    this.getMonthDates();
                    this.showLoadData = false;
                })
                .catch(err => this.onHttpError(err));
        }
    }

    isFuture = (reminder: Reminder): boolean => new Date(reminder.activeAfterUtc).getTime() > new Date().getTime();

    getDayReminders = (date: Date): Reminder[] => {
        const dates = [];
        this.data.forEach((reminder: Reminder) => {
            if (this.dateFns.isEqual(this.formatedDate(date), this.formatedDate(reminder.activeAfterUtc))) {
                dates.push(reminder);
            }
        });
        return dates.length > 1 ? dates.sort((a, b) => new Date(a.activeAfterUtc).getTime() - new Date(b.activeAfterUtc).getTime()) : dates;
    }

    getMonthDates = (): void => {
        this.daysStartDate = dateFns.startOfWeek(this.currentMonth);
        this.monthStart = dateFns.startOfMonth(this.currentMonth);
        this.monthEnd = dateFns.endOfMonth(this.monthStart);
        this.startDate = dateFns.startOfWeek(this.monthStart);
        this.endDate = dateFns.endOfWeek(this.monthEnd);
        this.calendar = new Calendar();
        let format: Date;
        while (this.startDate <= this.endDate) {
            const row = new Row();
            for (let i = 0; i < 7; i++) {
                const day = new CalendarDay();
                format = this.formatedDate(this.startDate) as Date;
                day.date = format as Date;
                day.reminders = this.getDayReminders(format);
                row.data.push(day);
                this.startDate = dateFns.addDays(this.startDate, 1);
            }
            this.calendar.row.push(row);
        }
    }

    createReminder = (): void => {
        this.editReminder.activeAfterUtc = new Date(new Date(this.editReminder.activeAfterUtc).toUTCString());
        this.showLoadData = true;
        if (this.editReminder.id) {
            this.reminderSvc.update(this.editReminder)
                .then((res: Reminder) => {
                    if (res) {
                        const index1 = this.data.findIndex(x => x.id === res.id);
                        const index2 = this.activeReminders.findIndex(x => x.id === res.id);
                        if (index1 >= 0) { this.data[index1] = res; }
                        if (index2 >= 0) { this.activeReminders[index2] = res; }
                        this.updateData();
                        this.showLoadData = false;
                        this.editReminder = null;
                    }
                })
                .catch(err => this.onHttpError(err));
        } else {
            this.reminderSvc.create(this.editReminder)
                .then((res: Reminder) => {
                    this.data.push(res);
                    this.updateData();
                    this.showLoadData = false;
                    this.editReminder = null;
                })
                .catch(err => this.onHttpError(err));
        }
    }

    addCurrentTime = (date: Date): Date => {
        date = new Date(date);
        const hours = new Date().getHours();
        const minutes = new Date().getMinutes();
        const newDate = new Date(date.setHours(hours));
        return new Date(newDate.setMinutes(minutes));
    }

    addReminder = (date: Date, e: Event): void => {
        e && e.stopPropagation();
        this.editReminder = new Reminder();
        this.editReminder.activeAfterUtc = this.addCurrentTime(date);
        if (this.reminderConfig && this.reminderConfig.type == ReminderCategoryType.Application) {
            this.editReminder.relatedAppUser = this.reminderConfig.object
            // this.editReminder.headerText = `${this.reminderConfig.name}`;
        } 
    }

    dismissReminder = (reminder: Reminder, e: Event): void => {
        e && e.stopPropagation();
        if (reminder && !reminder.isDismissed && !this.isFuture(reminder)) {
            this.confirm.show('confirm', 'Are you sure to dismiss this item?')
                .then(answer => {
                    if (answer) {
                        this.showLoadData = true;
                        this.reminderSvc.dismiss(reminder)
                            .then(res => {
                                this.showLoadData = false;
                                if (res) {
                                    const index = this.data.findIndex(x => x.id === res.id);
                                    if (index >= 0) { this.data[index] = res; }
                                    this.updateData();
                                } else {
                                    const index1 = this.data.findIndex(x => x.id === reminder.id);
                                    const index2 = this.activeReminders.findIndex(x => x.id === reminder.id);
                                    if (index1 >= 0) { this.data[index1].isDismissed = true; }
                                    if (index2 >= 0) { this.activeReminders[index2].isDismissed = true; }
                                    this.updateData();
                                }
                            })
                            .catch(err => this.onHttpError(err));
                    }
                });
        }
    }

    deleteReminder = (reminder: Reminder, e: Event): void => {
        e && e.stopPropagation();
        if (reminder) {
            this.confirm.show('confirm', 'Are you sure to delete this item?')
                .then(answer => {
                    if (answer) {
                        this.showLoadData = true;
                        this.reminderSvc.delete(reminder)
                            .then(() => {
                                const index1 = this.data.findIndex(x => x.id === reminder.id);
                                const index2 = this.activeReminders.findIndex(x => x.id === reminder.id);
                                if (index1 >= 0) { this.data.splice(index1, 1); }
                                if (index2 >= 0) { this.activeReminders.splice(index2, 1); }
                                this.updateData();
                                this.showLoadData = false;
                            })
                            .catch(err => this.onHttpError(err));
                    }
                });
        }
    }

    clickEvent = (reminder: Reminder, e: Event): void => {
        e && e.stopPropagation();
        this.showLoadData = true;
        this.reminderSvc.getReminder(reminder.id)
            .then(res => {
                this.editReminder = res;
                this.editReminder.sendEmail = res && res.task && res.task.type === ReminderTaskType.SendEmail && !res.task.isCompleted;
                if (this.editReminder.activeAfterUtc) {
                    const hours = new Date(this.editReminder.activeAfterUtc).getHours();
                    const minutes = new Date(this.editReminder.activeAfterUtc).getMinutes();
                    this.editReminder.time = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
                }
                this.showDetails = true;
                this.showLoadData = false;
            })
            .catch(err => this.onHttpError(err));
    }

    nextMonth = (e: Event): void => {
        e && e.stopPropagation();
        this.currentMonth = dateFns.addMonths(this.currentMonth, 1);
        this.getCurrentReminders();
    }

    prevMonth = (e: Event): void => {
        e && e.stopPropagation();
        this.currentMonth = dateFns.subMonths(this.currentMonth, 1);
        this.getCurrentReminders();
    }

    selectEvent = (e: Event): void => {
        e && e.stopPropagation();
        this.showCalendar = false;
    }

    outsideClick = (e: Event): void => {
        e ? e.stopPropagation() : this.showCalendar = false;
    }

    cancelEdit = (e: Event): void => {
        e && e.stopPropagation();
        this.editReminder = null;
        this.showDetails = false;
    }

    formatedDate(inDate: Date): string | Date {
        if (inDate) {
            inDate = new Date(inDate);
            return `${inDate.getMonth() + 1}/${inDate.getDate()}/${inDate.getFullYear()}`;
        } else {
            return new Date();
        }
    }
}
